

export  const ENV = {
	sprint1: process.env.REACT_APP_SPRINT_1,
	sprint2: process.env.REACT_APP_SPRINT_2,
	sprint3: process.env.REACT_APP_SPRINT_3,
	sprint4: process.env.REACT_APP_SPRINT_4,
	appMode: process.env.REACT_APP_APP_MODE

}

export const isCodeReady = sprintState => {
	let res = true;
	if ( sprintState !== 'DONE' &&  ENV.appMode == 'PROD' ) {
		res = false;
	} 
	return res
}